import styles from "./ProfileReadSection.module.css";
import Markdown from "react-markdown";
import { CMSItem, CMSMedia } from "@greenflagdate/shared";
import {
  QuestionsSectionAttributes,
  ModuleAttributes,
  OutcomeAttributes,
} from "../../../lib/quizDataHelpers";
import { Card } from "../../../components/Card/Card";
import { BackLink } from "../../../components/BackLink/BackLink";
import { PublicUserWithProfile } from "../../../lib/Schema";

interface HeaderProps {
  badge?: CMSItem<CMSMedia>;
  badgeAlt: string;
  label?: string;
  value: string;
}

function Header({ badge, badgeAlt, label, value }: HeaderProps) {
  return (
    <div className={styles.header}>
      {!!badge && (
        <img
          src={badge.attributes.url}
          alt={badgeAlt}
          className={styles.badge}
          width={badge.attributes.width}
          height={badge.attributes.height}
        />
      )}
      <div className={styles.headerContent}>
        {!!label && <div className={styles.headerLabel}>{label}</div>}
        <div className={styles.headerValue}>{value}</div>
      </div>
    </div>
  );
}

interface AnswersProps {
  quizSection: CMSItem<QuestionsSectionAttributes>;
  user: PublicUserWithProfile;
  quizModule: CMSItem<ModuleAttributes>;
}

function Answers({ quizSection, user, quizModule }: AnswersProps) {
  return (
    <>
      <div className={styles.answersTitle}>
        {user.first_name}&apos;s Responses
      </div>
      <div>
        {quizSection.attributes.questions.data.map((q) => {
          const answerModule = user.profile.get(quizModule.id);
          const answerSection = answerModule?.sections.get(quizSection.id);
          const answer = answerSection?.questions?.get(q.id);
          if (!answer) {
            return null;
          }
          const option =
            q.attributes.options?.data.find((a) => a.id === answer.answerId)
              ?.attributes.label || "";
          const answerText = option + (answer.explain || "");
          return (
            <div className={styles.questionContainer} key={q.id}>
              <div className={styles.label}>Q: {q.attributes.question}</div>
              <div>A: {answerText}</div>
            </div>
          );
        })}
      </div>
    </>
  );
}

interface OutcomeProps {
  outcome: CMSItem<OutcomeAttributes>;
  quizSection: CMSItem<QuestionsSectionAttributes>;
  user: PublicUserWithProfile;
  quizModule: CMSItem<ModuleAttributes>;
}

function Outcome({ outcome, quizSection, user, quizModule }: OutcomeProps) {
  return (
    <div>
      <Header
        badge={outcome.attributes.badge?.data || undefined}
        badgeAlt={`${outcome.attributes.name} badge`}
        label={
          (outcome?.attributes.badge?.data || undefined) &&
          `${user.first_name}'s ${
            quizSection?.attributes.profileName || quizSection?.attributes.name
          }`
        }
        value={
          outcome?.attributes.badge?.data
            ? outcome.attributes.name
            : (quizSection?.attributes.profileName &&
                quizSection?.attributes.profileName) ||
              quizSection?.attributes.name
        }
      />
      {!!outcome.attributes.profileFormattedDescription && (
        <Markdown className={styles.description}>
          {outcome.attributes.profileFormattedDescription.replace(
            /\{FIRST_NAME\}/g,
            user.first_name || ""
          )}
        </Markdown>
      )}
      <Answers quizSection={quizSection} user={user} quizModule={quizModule} />
    </div>
  );
}

interface SectionProps {
  quizSection: CMSItem<QuestionsSectionAttributes>;
  user: PublicUserWithProfile;
  quizModule: CMSItem<ModuleAttributes>;
}

function Section({ quizSection, user, quizModule }: SectionProps) {
  return (
    <div>
      <Header
        badge={quizSection.attributes.badge?.data || undefined}
        badgeAlt={`${quizSection.attributes.name} badge`}
        value={`${user.first_name}'s ${quizSection?.attributes.profileName}`}
      />
      <Answers quizSection={quizSection} user={user} quizModule={quizModule} />
    </div>
  );
}

interface ProfileSectionProps {
  quizSection: CMSItem<QuestionsSectionAttributes>;
  user: PublicUserWithProfile;
  outcome?: CMSItem<OutcomeAttributes>;
  quizModule: CMSItem<ModuleAttributes>;
}

export function ProfileReadSection({
  quizSection,
  outcome,
  user,
  quizModule,
}: ProfileSectionProps) {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: outcome?.attributes.color }}
    >
      <div className={styles.containerWidth}>
        <BackLink href={`/${user.slug}`} />
        <Card className={styles.card}>
          {outcome ? (
            <Outcome
              outcome={outcome}
              quizSection={quizSection}
              user={user}
              quizModule={quizModule}
            />
          ) : (
            <Section
              quizSection={quizSection}
              user={user}
              quizModule={quizModule}
            />
          )}
        </Card>
      </div>
    </div>
  );
}
