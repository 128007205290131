import classNames from "classnames";
import posthog from "posthog-js";
import { useState } from "react";
import { SchemaQuestion } from "../../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import {
  ModuleAttributes,
  QuestionsSectionAttributes,
} from "../../../../lib/quizDataHelpers";
import { schemaStore } from "../../../../lib/schemaStore";
import { LockSwitch } from "../../../../components/LockSwitch/LockSwitch";

import styles from "./ProfileViewAnswers.module.css";
import {
  faChevronDown,
  faChevronRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ProfileViewAnswersProps {
  questions: Map<number, SchemaQuestion>;
  mod: CMSItem<ModuleAttributes>;
  section: CMSItem<QuestionsSectionAttributes>;
  editable?: boolean;
  disabled?: boolean;
}

export function ProfileViewAnswers({
  questions,
  mod,
  section,
  editable,
  disabled,
}: ProfileViewAnswersProps) {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className={styles.content}>
      <button className={styles.button} onClick={() => setExpanded(!expanded)}>
        <div className={styles.icon}>
          <FontAwesomeIcon
            icon={faCircleQuestion}
            color="var(--color-primary-900)"
            style={{ fontSize: "22px" }}
          />
        </div>
        <div className={styles.buttonLabel}>View Answers</div>
        {expanded ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </button>
      {expanded && (
        <div className={styles.answers}>
          {Array.from(questions.entries()).map(([questionId, question]) => {
            const quizQuestion = section.attributes.questions.data.find(
              (qq) => qq.id === questionId
            );
            if (!quizQuestion) {
              return null;
            }
            return (
              <div
                className={classNames(styles.questionContainer, {
                  [styles.private]: !question.public && !disabled,
                })}
                key={questionId}
              >
                <div className={styles.questionTitleRow}>
                  <div className={styles.label}>
                    Q: {quizQuestion.attributes.question}
                  </div>
                  {editable && !disabled && (
                    <LockSwitch
                      size="small"
                      className={styles.privacyButton}
                      checked={!!question.public}
                      onChange={(pub) => {
                        posthog.capture("privacyChange", {
                          type: "question",
                          id: questionId,
                          source: "ProfileViewAnswers",
                          value: pub,
                        });
                        schemaStore.updateQuestion(mod, section, questionId, {
                          public: pub,
                        });
                      }}
                    />
                  )}
                </div>
                <div className={styles.answerContainer}>
                  <div className={styles.answer}>A:</div>&nbsp;
                  <div className={styles.value}>
                    {question.answerId ? (
                      <div>
                        {
                          quizQuestion.attributes.options?.data.find(
                            (o) => o.id === question.answerId
                          )?.attributes.label
                        }
                      </div>
                    ) : (
                      question.explain
                    )}
                  </div>
                </div>
                {!!question.answerId && !!question.explain && (
                  <div className={styles.answerContainer}>
                    <div className={styles.label}>Additional Comments:</div>
                    &nbsp;
                    <div className={styles.value}>{question.explain}</div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
