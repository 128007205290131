import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuizData } from "../../../lib/quizDataHelpers";
import { Loader } from "../../../components/Loader/Loader";

import styles from "./Discover.module.css";
import { useEffect } from "react";
import { useSchemaStore } from "../../../lib/useSchemaStore";
import { mvpSlug } from "../../../lib/mvpSlug";

export const Discover = () => {
  const { loading, quizData } = useQuizData();
  const location = useLocation();
  const schemaData = useSchemaStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && quizData && location.pathname === "/discover") {
      let currentModule = quizData.data[1];
      for (const m of quizData.data) {
        currentModule = m;
        if (!schemaData.modules.get(m.id)?.completed) {
          break;
        }
      }
      navigate(`/${mvpSlug}/${currentModule.attributes.slug}`);
    }
  }, [loading, location.pathname, navigate, quizData, schemaData.modules]);

  return (
    <>
      {!schemaData.loaded || loading || !quizData ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <Outlet context={{ quizData, schemaData }} />
      )}
    </>
  );
};

// import { mvpSlug } from "@/lib/mvpSlug";
// import { staticProps } from "@/lib/quizDataHelpers";
// import { useSchemaStore } from "@/lib/useSchemaStore";
// import { InferGetStaticPropsType } from "next";
// import { useRouter } from "next/navigation";

// export const getStaticProps = staticProps;

// export default function Quiz({
//   quizData,
// }: InferGetStaticPropsType<typeof getStaticProps>) {
//   const router = useRouter();
//   const store = useSchemaStore();
//   let currentModule = quizData.data[0];
//   for (const m of quizData.data) {
//     currentModule = m;
//     if (!store.modules.get(m.id)?.completed) {
//       break;
//     }
//   }
//   if (typeof window === "object") {
//     router.replace(`/${mvpSlug}/${currentModule.attributes.slug}`);
//   }
//   return null;
// }
