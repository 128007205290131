import classNames from "classnames";
import posthog from "posthog-js";
import { useMemo } from "react";
import Markdown from "react-markdown";
import { SchemaSection, SchemaQuestion } from "../../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import {
  QuestionsSectionAttributes,
  ModuleAttributes,
  getOutcome,
} from "../../../../lib/quizDataHelpers";
import { schemaStore } from "../../../../lib/schemaStore";
import { LockSwitch } from "../../../../components/LockSwitch/LockSwitch";
import { ProfileAnswer } from "./ProfileAnswer";
import { ProfileViewAnswers } from "./ProfileViewAnswers";

import styles from "./ProfileSection.module.css";

interface ProfileSectionProps {
  section: SchemaSection;
  quizSection: CMSItem<QuestionsSectionAttributes>;
  quizModule: CMSItem<ModuleAttributes>;
  editable: boolean;
}

export function ProfileSection({
  section,
  quizSection,
  quizModule,
  editable,
}: ProfileSectionProps) {
  const questions = useMemo(
    () => section.questions || new Map<number, SchemaQuestion>(),
    [section.questions]
  );
  const outcome = useMemo(() => {
    const o = getOutcome(quizSection, section);
    if (typeof o === "string") {
      return undefined;
    }
    return o;
  }, [quizSection, section]);

  return (
    <div
      className={classNames(styles.content, {
        [styles.private]: section.private,
      })}
    >
      <div className={styles.header}>
        {!!outcome?.attributes.badge?.data && (
          <img
            src={outcome.attributes.badge.data.attributes.url}
            alt={`${outcome.attributes.name} badge`}
            className={styles.badge}
            width={outcome.attributes.badge.data.attributes.width}
            height={outcome.attributes.badge.data.attributes.height}
          />
        )}
        <div className={styles.titleRow}>
          <div className={styles.title}>{quizSection.attributes.name}</div>
          {!!outcome && (
            <div className={styles.subtitle}>
              <div>{outcome.attributes.summaryPrefix}</div>
              &nbsp;
              <div className={styles.outcomeName}>
                {outcome.attributes.name}
              </div>
            </div>
          )}
        </div>
        {editable && (
          <LockSwitch
            size="small"
            className={styles.privacyButton}
            checked={!section.private}
            onChange={(pub) => {
              posthog.capture("privacyChange", {
                type: "section",
                id: section.id,
                source: "ProfileSection",
                value: pub,
              });
              schemaStore.updateSection(section.id, quizModule, {
                private: !pub,
              });
            }}
          />
        )}
      </div>
      {!!outcome?.attributes.graphic?.data && (
        <img
          src={outcome.attributes.graphic.data.attributes.url}
          alt={`${outcome.attributes.name} graphic`}
          className={styles.graphic}
          width={outcome.attributes.graphic.data.attributes.width}
          height={outcome.attributes.graphic.data.attributes.height}
        />
      )}
      {!!outcome && (
        <>
          <div className={styles.outcomeDescription}>
            {outcome.attributes.formattedDescription ? (
              <Markdown>{outcome.attributes.formattedDescription}</Markdown>
            ) : (
              outcome.attributes.description
            )}
          </div>
          {!!questions.size && (
            <ProfileViewAnswers
              questions={questions}
              mod={quizModule}
              section={quizSection}
              editable={editable}
              disabled={section.private}
            />
          )}
        </>
      )}
      {!outcome &&
        Array.from(questions).map(([questionId, q]) => {
          const quizQuestion = quizSection.attributes.questions.data.find(
            (qq) => qq.id === q.id
          );
          if (!quizQuestion) {
            return null;
          }
          return (
            <ProfileAnswer
              question={q}
              quizQuestion={quizQuestion}
              mod={quizModule}
              section={quizSection}
              key={questionId}
              editable={editable}
              disabled={section.private}
            />
          );
        })}
    </div>
  );
}
