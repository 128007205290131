import classNames from "classnames";
import styles from "./SectionVideoThumbnail.module.css";
import { useState } from "react";
import { PlayIcon } from "../PlayIcon/PlayIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface SectionVideoThumbnailProps {
  thumbnailImage: JSX.Element;
  title?: string;
  length?: string;
  disabled?: boolean;
}
export function SectionVideoThumbnail({
  thumbnailImage,
  title,
  length,
  disabled,
}: SectionVideoThumbnailProps) {
  const [videoHover, setVideoHover] = useState(false);
  return (
    <div
      className={classNames(styles.container, { [styles.disabled]: disabled })}
      onMouseOver={() => setVideoHover(true)}
      onMouseOut={() => setVideoHover(false)}
    >
      <div className={styles.imageContainer}>
        {thumbnailImage}
        <PlayIcon size={40} className={styles.playIcon} hover={videoHover} />
      </div>
      {(title || length) && (
        <div className={styles.caption}>
          {title && <div className={styles.title}>{title}</div>}
          {length && <div className={styles.length}>{length}</div>}
        </div>
      )}
      <FontAwesomeIcon
        icon={faChevronRight}
        className={classNames({ [styles.disabledChevron]: disabled })}
      />
    </div>
  );
}
