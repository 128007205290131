import classNames from "classnames";
import posthog from "posthog-js";
import { SchemaQuestion } from "../../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import {
  QuestionAttributes,
  ModuleAttributes,
  QuestionsSectionAttributes,
} from "../../../../lib/quizDataHelpers";
import { schemaStore } from "../../../../lib/schemaStore";
import { LockSwitch } from "../../../../components/LockSwitch/LockSwitch";

import styles from "./ProfileAnswer.module.css";

interface ProfileAnswerProps {
  question: SchemaQuestion;
  quizQuestion: CMSItem<QuestionAttributes>;
  mod: CMSItem<ModuleAttributes>;
  section: CMSItem<QuestionsSectionAttributes>;
  editable: boolean;
  disabled?: boolean;
}

export function ProfileAnswer({
  question,
  quizQuestion,
  mod,
  section,
  editable,
  disabled,
}: ProfileAnswerProps) {
  return (
    <div className={styles.content}>
      <div className={styles.questionRow}>
        <div
          className={classNames(styles.label, {
            [styles.disabled]: !disabled && !question.public,
          })}
        >
          {quizQuestion.attributes.question}
        </div>
        {editable && (
          <LockSwitch
            size="small"
            className={styles.privacyButton}
            checked={!!question.public}
            onChange={(pub) => {
              posthog.capture("privacyChange", {
                type: "question",
                id: question.id,
                source: "ProfileAnswer",
                value: pub,
              });
              schemaStore.updateQuestion(mod, section, question.id, {
                public: pub,
              });
            }}
          />
        )}
      </div>

      {!!question.answerId && (
        <div
          className={classNames(styles.value, {
            [styles.disabled]: !disabled && !question.public,
          })}
        >
          {
            quizQuestion.attributes.options?.data.find(
              (o) => o.id === question.answerId
            )?.attributes.label
          }
        </div>
      )}
      {!!question.explain && (
        <div
          className={classNames(styles.value, {
            [styles.disabled]: !disabled && !question.public,
          })}
        >
          {question.explain}
        </div>
      )}
    </div>
  );
}
