import { useEffect } from "react";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  QuizData,
  ModuleAttributes,
  VideoSectionAttributes,
  getModule,
  getSection,
  isQuestionSection,
  isVideoSection,
} from "../../../lib/quizDataHelpers";
import { schemaStore } from "../../../lib/schemaStore";
import { useIsClient } from "../../../lib/useIsClient";
import { useSchemaStore } from "../../../lib/useSchemaStore";
import { Video } from "../../../components/Video/Video";
import { Meta } from "../../../components/Meta/Meta";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { NotFound } from "../../../components/NotFound/NotFound";

interface VideoSectionProps {
  module: CMSItem<ModuleAttributes>;
  section: CMSItem<VideoSectionAttributes>;
}

function VideoSection({ module: m, section }: VideoSectionProps) {
  const navigate = useNavigate();
  useEffect(() => {
    schemaStore.updateSection(section.id, m, { completed: true });
  }, [m, section.id]);
  return (
    <>
      <Meta>
        <title>{section.attributes.name} - Discover your Green Flags</title>
      </Meta>
      <Video
        url={
          section?.attributes.video.data.attributes.video.data.attributes.url
        }
        color={""}
        onClose={() => navigate(`/${mvpSlug}/${m.attributes.slug}`)}
      />
    </>
  );
}

export function DiscoverSection() {
  const { quizData } = useOutletContext<{
    quizData: QuizData;
  }>();
  const { moduleSlug, sectionSlug } = useParams();
  const isClient = useIsClient();
  const navigate = useNavigate();
  const data = useSchemaStore();
  const m = getModule(quizData, moduleSlug!);
  const section = getSection(quizData, moduleSlug!, sectionSlug!);
  if (!moduleSlug || !sectionSlug || !m || !section) {
    return <NotFound />;
  }
  if (isQuestionSection(section)) {
    let firstUnansweredQuestion;
    for (const q of section.attributes.questions.data) {
      const answer = data.modules
        .get(m.id)
        ?.sections.get(section.id)
        ?.questions?.get(q.id);
      if (!answer?.answerId && !answer?.explain) {
        firstUnansweredQuestion = q;
        break;
      }
    }
    const currentQuestion =
      firstUnansweredQuestion || section.attributes.questions.data[0];
    if (isClient) {
      navigate(
        `/${mvpSlug}/${m.attributes.slug}/${section.attributes.slug}/${currentQuestion.id}`
      );
    }
    return null;
  } else if (isVideoSection(section)) {
    return <VideoSection module={m} section={section} />;
  }
  return null;
}
