import { getMondayForSpark } from "@greenflagdate/shared";
import { store } from "./store/store";
import dayjs from "dayjs";
import { isLoggedIn } from "./isLoggedIn";

export const getCurrentSparkPage = () => {
  const {
    data: { sparkRegistration, user },
  } = store;
  const currentMonday = getMondayForSpark().toISOString();
  if (!sparkRegistration.activities?.length) {
    return "/app/spark/activities";
  }
  if (
    !sparkRegistration.availableDays?.some((d) =>
      dayjs(d).isAfter(currentMonday)
    )
  ) {
    return "/app/spark/availability";
  }
  if (!sparkRegistration.neighborhoods?.length) {
    return "/app/spark/neighborhoods";
  }
  const birthDate = new Date(
    user?.birth_date || sparkRegistration.birthDate || ""
  );
  if (isNaN(birthDate.getTime()) || birthDate.getFullYear() === 0) {
    return "/app/spark/birth-date";
  }
  if (!user?.gender && !sparkRegistration.gender) {
    return "/app/spark/gender";
  }
  if (!user?.gender_preference && !sparkRegistration.genderPreference) {
    return "/app/spark/gender";
  }
  if (!user?.first_name && !sparkRegistration.firstName) {
    return "/app/spark/name";
  }
  if (!isLoggedIn(store.data) || !user?.email) {
    return "/app/spark/email";
  }
  if (!("canLead" in sparkRegistration)) {
    return "/app/spark/leader";
  }
  return "/app/spark/checkout";
};
