import classNames from "classnames";

import styles from "./MultipleChoice.module.css";
import checkImage from "./images/check.svg";

export interface QuestionOption {
  id: number;
  label: string;
  isOther?: boolean;
}

interface ChoiceProps {
  option: QuestionOption;
  checked: boolean;
  onClick: () => void;
}

function Choice({ option, checked, onClick }: ChoiceProps) {
  return (
    <div
      className={classNames(styles.option, { [styles.checked]: checked })}
      onClick={onClick}
    >
      <div className={styles.circle}>
        {checked && <img src={checkImage} alt="Checked" />}
      </div>
      <div className={styles.label}>{option.label}</div>
    </div>
  );
}

interface MultipleChoiceProps {
  options: QuestionOption[];
  onChange: (option: QuestionOption) => void;
  value?: number;
  className?: string;
}

export function MultipleChoice({
  options,
  value,
  onChange,
  className,
}: MultipleChoiceProps) {
  return (
    <div className={classNames(styles.container, className)}>
      {options.map((option) => (
        <Choice
          key={option.id}
          option={option}
          checked={option.id === value}
          onClick={() => onChange(option)}
        />
      ))}
    </div>
  );
}
