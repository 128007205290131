import classNames from "classnames";
import styles from "./LockSwitch.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";

interface LockSwitchProps {
  checked: boolean;
  onChange: (val: boolean) => void;
  className?: string;
  size: "small" | "medium";
}

export function LockSwitch({
  checked,
  onChange,
  className,
  size,
}: LockSwitchProps) {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.checked]: checked },
        styles[size],
        className
      )}
    >
      <button className={styles.button} onClick={() => onChange(!checked)}>
        <div className={styles.lockContainer}>
          {checked ? (
            <FontAwesomeIcon
              icon={faUnlock}
              color="var(--color-white)"
              style={{ fontSize: size === "medium" ? 16 : 12 }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faLock}
              color="var(--color-white)"
              style={{ fontSize: size === "medium" ? 16 : 12 }}
            />
          )}
        </div>
        {size === "medium" && (
          <div className={styles.label}>{checked ? "Public" : "Private"}</div>
        )}
      </button>
      {size === "small" && (
        <div className={styles.label}>{checked ? "Public" : "Private"}</div>
      )}
    </div>
  );
}
