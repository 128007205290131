import { Button } from "../Button/Button";
import { useState } from "react";
import { SaveModal } from "../SaveModal/SaveModal";
import styles from "./SaveButton.module.css";
import { UserWithMediaAndProfile } from "../../lib/Schema";

interface SaveButtonProps {
  user?: UserWithMediaAndProfile;
  onClick?: () => void;
}

export function SaveButton({ user, onClick }: SaveButtonProps) {
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  return user ? (
    <Button
      label="Progress Saved!"
      size="x-small"
      outline
      disabled={true}
      className={styles.button}
    />
  ) : (
    <>
      <Button
        label="Save Progress"
        size="x-small"
        outline
        onClick={() => {
          if (onClick) {
            onClick();
          }
          setSaveModalOpen(true);
        }}
        className={styles.button}
      />
      <SaveModal
        isOpen={saveModalOpen}
        onClose={() => setSaveModalOpen(false)}
      />
    </>
  );
}
