import classNames from "classnames";
import { PropsWithChildren, useRef, useMemo } from "react";
import {
  PublicUserWithProfile,
  reviver,
  SchemaModule,
  SchemaSection,
  UserWithMediaAndProfile,
} from "../../../../lib/Schema";
import { CMSItem, UserWithMedia } from "@greenflagdate/shared";
import {
  QuizData,
  SectionAttributes,
  ModuleAttributes,
  QuestionsSectionAttributes,
} from "../../../../lib/quizDataHelpers";
import { useSchemaStore } from "../../../../lib/useSchemaStore";
import { ProfileSection } from "./ProfileSection";
import { Meta } from "../../../../components/Meta/Meta";

import styles from "./Profile.module.css";
import { NotFound } from "../../../../components/NotFound/NotFound";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../../lib/apiReq";

interface ProfileProps {
  slug: string;
  loggedInUser?: UserWithMediaAndProfile;
  quizData: QuizData;
}

export function ManageProfile({
  slug,
  loggedInUser,
  quizData,
}: PropsWithChildren<ProfileProps>) {
  const [request, requestState] = useReq<UserWithMedia>(apiReq);
  const data = useSchemaStore();
  const fetchingUser = useRef(false);

  const user: PublicUserWithProfile | false = useMemo(() => {
    if (loggedInUser) {
      return {
        ...loggedInUser,
        profile: data.modules,
      };
    }
    if (requestState.result) {
      return {
        ...requestState.result,
        profile: JSON.parse(requestState.result.profile, reviver) as Map<
          number,
          SchemaModule
        >,
      } as UserWithMediaAndProfile;
    }
    if (!requestState.loading && !requestState.error && !fetchingUser.current) {
      fetchingUser.current = true;
      request.get(`/user/${slug}`);
    }
    return false;
  }, [
    data.modules,
    loggedInUser,
    request,
    requestState.error,
    requestState.loading,
    requestState.result,
    slug,
  ]);

  if (requestState.error) {
    return <NotFound />;
  }
  return (
    <>
      {!!user && !loggedInUser && (
        <Meta>
          <title>{user.first_name}&apos;s Green Flags</title>
        </Meta>
      )}

      <div>
        {!!user && (
          <>
            <div className={classNames(styles.section, styles.userInfo)}>
              <div className={styles.sectionContent}>
                <div className={styles.name}>{user.first_name}</div>
                <div className={styles.age}>{user.age}</div>
                <div className={styles.location}>{user.location}</div>
              </div>
            </div>
            {Array.from(user.profile.entries())
              .reduce(
                (agg, [moduleId, mod]) => {
                  const sections = Array.from(mod.sections.entries()).map(
                    ([sectionId, section]) => {
                      const quizModule = quizData.data.find(
                        (m) => m.id === moduleId
                      );
                      const quizSection =
                        quizModule?.attributes.sections.data.find(
                          (s) => s.id === sectionId
                        );
                      return { section, quizSection, quizModule };
                    }
                  );
                  return [...agg, ...sections];
                },
                [] as {
                  section: SchemaSection;
                  quizSection: CMSItem<SectionAttributes> | undefined;
                  quizModule: CMSItem<ModuleAttributes> | undefined;
                }[]
              )
              .filter(
                (d) =>
                  d.quizSection &&
                  d.quizSection.attributes.type === "questions" &&
                  !d.quizSection.attributes.private
              )
              .map(({ section, quizSection, quizModule }) => {
                return (
                  <div className={styles.section} key={section.id}>
                    <ProfileSection
                      section={section}
                      quizSection={
                        quizSection as CMSItem<QuestionsSectionAttributes>
                      }
                      quizModule={quizModule!}
                      editable={!!loggedInUser}
                    />
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
}
