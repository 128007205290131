import styles from "./BackLink.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface BackLinkProps {
  href: string;
}

export function BackLink({ href }: BackLinkProps) {
  return (
    <Link className={styles.back} to={href}>
      <FontAwesomeIcon icon={faChevronLeft} />
      Back
    </Link>
  );
}
