import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Video.module.css";
import closeImage from "./images/close.svg";

interface VideoProps {
  url: string;
  thumbnail?: JSX.Element;
  color: string;
  startTime?: number;
  caption?: string;
  length?: string;
  onTick?: (state: PlayerState) => void;
  onComplete?: () => void;
  disabled?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  className?: string;
}

export interface PlayerState {
  status: "paused" | "waiting" | "playing" | "muted";
  playTime: number;
  waitTime: number;
  lastCheck: number;
  currentTime: number;
}

export const Video = React.memo((props: VideoProps) => {
  const {
    url,
    thumbnail,
    onTick,
    onComplete,
    startTime,
    disabled,
    onClose,
    onOpen,
    className,
  } = props;
  const [fs, setFs] = useState(false);
  const player = useRef<HTMLVideoElement>(null);
  const state = useRef<PlayerState>({
    status: "paused",
    playTime: 0,
    waitTime: 0,
    currentTime: 0,
    lastCheck: Date.now(),
  });

  useEffect(() => {
    const i = setInterval(() => {
      const now = Date.now();
      state.current.currentTime = player.current?.currentTime || 0;
      if (state.current.status === "playing") {
        state.current.playTime += now - state.current.lastCheck;
      } else if (state.current.status === "waiting") {
        state.current.waitTime += now - state.current.lastCheck;
      }
      state.current.lastCheck = now;
      if (fs && onTick) {
        onTick(state.current);
      }
    }, 1000);
    if (player.current && startTime) {
      player.current.currentTime = startTime;
    }
    return () => clearInterval(i);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTick, fs]);
  return (
    <>
      {thumbnail && (
        <div
          className={classNames(styles.thumbnailWrapper, className)}
          onClick={() => {
            if (!disabled) {
              if (onOpen) {
                onOpen();
              }
              setFs(true);
              if (player.current) {
                player.current.play();
              }
            }
          }}
        >
          {thumbnail}
        </div>
      )}

      <div
        className={classNames(styles.playerWrapper, {
          [styles.fullScreen]: !thumbnail || fs,
        })}
      >
        <video
          src={url}
          preload="none"
          controls
          className={styles.player}
          ref={player}
          playsInline
          onPlaying={() => {
            const now = Date.now();
            if (state.current.status === "waiting") {
              state.current.waitTime += now - state.current.lastCheck;
            }
            state.current.lastCheck = now;
            state.current.status = "playing";
          }}
          onPause={() => {
            const now = Date.now();
            if (state.current.status === "waiting") {
              state.current.waitTime += now - state.current.lastCheck;
            }
            if (state.current.status === "playing") {
              state.current.playTime += now - state.current.lastCheck;
            }
            state.current.lastCheck = now;
            state.current.status = "paused";
          }}
          onEnded={() => {
            const now = Date.now();
            state.current.playTime += now - state.current.lastCheck;
            state.current.lastCheck = now;
            state.current.status = "paused";
            if (onComplete) {
              onComplete();
            }
          }}
          onWaiting={() => {
            const now = Date.now();
            state.current.waitTime += now - state.current.lastCheck;
            state.current.lastCheck = now;
            state.current.status = "waiting";
          }}
        />
        <img
          src={closeImage}
          alt="Close Video"
          className={styles.close}
          onClick={() => {
            if (player.current) {
              player.current.pause();
            }
            if (onClose) {
              onClose();
            }
            setFs(false);
          }}
        />
      </div>
    </>
  );
});
