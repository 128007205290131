import posthog from "posthog-js";
import { useOutletContext, Link, useNavigate } from "react-router-dom";
import { SchemaData } from "../../../lib/Schema";
import { mvpSlug } from "../../../lib/mvpSlug";
import { QuizData } from "../../../lib/quizDataHelpers";
import { Button } from "../../../components/Button/Button";
import { Meta } from "../../../components/Meta/Meta";
import { SaveModal } from "../../../components/SaveModal/SaveModal";
import { TextField } from "../../../components/TextField/TextField";
import { QuizActionPanel } from "../QuizModule/QuizActionPanel";
import { DiscoverSteps } from "./DiscoverSteps";
import toast, { Toaster } from "react-hot-toast";

import styles from "./DiscoverMyGreenFlags.module.css";
import { ManageProfile } from "./ManageProfile/ManageProfile";

export function DiscoverMyGreenFlags() {
  const { quizData, schemaData } = useOutletContext<{
    quizData: QuizData;
    schemaData: SchemaData;
  }>();
  const navigate = useNavigate();
  return (
    <>
      <Meta>
        <title>Discover your Green Flags</title>
      </Meta>
      <Toaster />
      <DiscoverSteps
        currentPageSlug={"my-green-flags"}
        store={schemaData}
        quizData={quizData}
      />
      {!!schemaData.token && schemaData.user && schemaData.user.slug && (
        <div className={styles.content}>
          <QuizActionPanel className={styles.profileAlert}>
            <div className={styles.maxWidth}>
              <div className={styles.shareTitle}>
                🎉🎉 Share your Green Flags! 🎉🎉
              </div>
              <div className={styles.shareSubtitle}>
                Put this link in your social media or dating profile.
              </div>
              <div className={styles.copyRow}>
                <TextField
                  value={`${import.meta.env.VITE_WEB_URL}/${
                    schemaData.user.slug
                  }`}
                  onFocus={(e) => {
                    posthog.capture("selectProfileUrl");
                    e.target.select();
                  }}
                  onChange={() => undefined}
                  style={{ height: "3rem" }}
                />
                <Button
                  label="Copy"
                  size="medium"
                  className={styles.copyButton}
                  onClick={() => {
                    if (schemaData.user?.slug) {
                      posthog.capture("copyProfileUrl");
                      navigator.clipboard.writeText(
                        `${import.meta.env.VITE_WEB_URL}/${
                          schemaData.user.slug
                        }`
                      );
                      toast("Profile copied to clipboard");
                    }
                  }}
                />
              </div>
              <div>
                <Link
                  to={`${import.meta.env.VITE_WEB_URL}/${schemaData.user.slug}`}
                  className={styles.previewLink}
                  target="_blank"
                >
                  PREVIEW LINK IN NEW TAB
                </Link>
              </div>
            </div>
          </QuizActionPanel>
          <ManageProfile
            slug={schemaData.user.slug}
            quizData={quizData}
            loggedInUser={schemaData.user}
          />
        </div>
      )}
      <SaveModal
        title="We need a few pieces of information to show your green flags"
        buttonLabel="View My Green Flags"
        isOpen={schemaData.loaded && !schemaData.token}
        onClose={(success) => {
          if (!success) {
            navigate(
              `/${mvpSlug}/${
                quizData.data[quizData.data.length - 2].attributes.slug
              }`
            );
          }
        }}
      />
    </>
  );
}
