import classNames from "classnames";
import { Link } from "react-router-dom";
import { SchemaData } from "../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  QuizData,
  ModuleAttributes,
  getModule,
} from "../../../lib/quizDataHelpers";
import checkImage from "./images/check.svg";

import styles from "./DiscoverSteps.module.css";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface QuizStepProps {
  currentPageSlug: string;
  store: SchemaData;
  quizData: QuizData;
}

type Status = "active" | "completed" | "disabled" | "available";

interface StepProps {
  status: Status;
  module: CMSItem<ModuleAttributes>;
}

function Step({ module: m, status }: StepProps) {
  return (
    <Link
      className={classNames(styles.step, styles[status])}
      to={`/${mvpSlug}/${m.attributes.slug}`}
      aria-disabled={["disabled", "active"].includes(status)}
    >
      <div
        className={classNames(styles.stepNumber, styles[`${status}StepNumber`])}
      >
        {status === "completed" && <img src={checkImage} alt="Completed" />}
        {status === "disabled" && (
          <FontAwesomeIcon
            icon={faLock}
            color="var(--color-white)"
            style={{ fontSize: "16px" }}
          />
        )}
        {!["completed", "disabled"].includes(status) && m.attributes.order}
      </div>
      <div className={styles.stepName}>{m.attributes.name}</div>
    </Link>
  );
}

function getStatus(
  quizData: QuizData,
  currentPageSlug: string,
  moduleId: number,
  store: SchemaData
): Status {
  const currentModule = getModule(quizData, currentPageSlug);
  const targetModule = getModule(quizData, moduleId);

  if (currentModule.id === moduleId) {
    return "active";
  } else if (store.modules.get(moduleId)?.completed) {
    return "completed";
  } else if (
    targetModule &&
    quizData.data
      .filter((m) => m.attributes.order < targetModule.attributes.order)
      .every(
        (m) => m.attributes.order === 0 || store.modules.get(m.id)?.completed
      )
  ) {
    return "available";
  }
  if (!store.modules.has(moduleId)) {
    return "disabled";
  } else {
    return "disabled";
  }
}

export function DiscoverSteps({
  currentPageSlug,
  store,
  quizData,
}: QuizStepProps) {
  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        {quizData.data.map((m, i) => (
          <Step
            key={m.id}
            module={m}
            status={
              i === 0
                ? "completed"
                : getStatus(quizData, currentPageSlug, m.id, store)
            }
          />
        ))}
      </div>
    </div>
  );
}
