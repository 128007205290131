import { useState, useMemo } from "react";
import Markdown from "react-markdown";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  QuizData,
  getModule,
  getSection,
  QuestionsSectionAttributes,
  getOutcome,
} from "../../../lib/quizDataHelpers";
import { useIsClient } from "../../../lib/useIsClient";
import { Button } from "../../../components/Button/Button";
import { SaveModal } from "../../../components/SaveModal/SaveModal";
import { QuizSectionHeader } from "./DiscoverQuestionHeader";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import styles from "./DiscoverOutcome.module.css";
import { Card } from "../../../components/Card/Card";
import { SchemaData } from "../../../lib/Schema";

export function DiscoverOutcome() {
  const { moduleSlug, sectionSlug } = useParams();
  const mSlug = moduleSlug || "";
  const sSlug = sectionSlug || "";
  const { quizData, schemaData } = useOutletContext<{
    quizData: QuizData;
    schemaData: SchemaData;
  }>();
  const navigate = useNavigate();
  const m = getModule(quizData, mSlug);
  const s = getSection(
    quizData,
    mSlug,
    sSlug
  ) as CMSItem<QuestionsSectionAttributes>;
  const isClient = useIsClient();
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const outcome = useMemo(() => {
    const sec = schemaData.modules.get(m.id)?.sections.get(s.id);
    if (!sec) {
      return undefined;
    }

    const outcome = getOutcome(s, sec);
    if (typeof outcome === "string") {
      if (isClient) {
        navigate(`/${mvpSlug}/${m.attributes.slug}/${outcome}`);
      }
      return undefined;
    }
    if (outcome === undefined) {
      if (isClient) {
        navigate(`/${mvpSlug}/${m.attributes.slug}`);
      }
    }
    return outcome;
  }, [schemaData.modules, isClient, m.attributes.slug, m.id, navigate, s]);

  return (
    <>
      <QuizSectionHeader module={m} user={schemaData.user} />
      <div className={styles.container}>
        <Card className={styles.card}>
          <div className={styles.header}>
            {!!outcome?.attributes.badge?.data && (
              <img
                src={outcome.attributes.badge.data.attributes.url}
                alt={`${outcome.attributes.name} badge`}
                className={styles.badge}
                width={outcome.attributes.badge.data.attributes.width}
                height={outcome.attributes.badge.data.attributes.height}
              />
            )}
            <div className={styles.titleRow}>
              <div className={styles.title}>{s.attributes.name}</div>
              <div className={styles.subtitle}>{outcome?.attributes.name}</div>
            </div>
          </div>
          {!!outcome?.attributes.graphic?.data && (
            <img
              src={outcome.attributes.graphic.data.attributes.url}
              alt={`${outcome.attributes.name} graphic`}
              className={styles.graphic}
              width={outcome.attributes.graphic.data.attributes.width}
              height={outcome.attributes.graphic.data.attributes.height}
            />
          )}
          <div className={styles.description}>
            {outcome?.attributes.formattedDescription ? (
              <Markdown>{outcome.attributes.formattedDescription}</Markdown>
            ) : (
              outcome?.attributes.description
            )}
          </div>
          <Button
            label="Continue"
            size="large"
            fullWidth
            onClick={() => {
              if (m.attributes.order === 1) {
                const firstQestionSection = m.attributes.sections.data.find(
                  (s) => s.attributes.type === "questions"
                );
                if (
                  firstQestionSection &&
                  s.id === firstQestionSection.id &&
                  !schemaData.token
                ) {
                  return setSaveModalOpen(true);
                }
              }
              navigate(`/${mvpSlug}/${m.attributes.slug}`);
            }}
          />
        </Card>
      </div>
      <SaveModal
        title="Would you like to save your progess?"
        isOpen={saveModalOpen}
        onClose={() => {
          navigate(`/${mvpSlug}/${m.attributes.slug}`);
          setSaveModalOpen(false);
        }}
      />
    </>
  );
}
