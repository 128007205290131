import {
  MaskedCreditCardInfo,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { useReq } from "@larner.dev/use-req";
import { Button, ButtonProps, Pane, PaneProps, Paragraph } from "evergreen-ui";
import { useState, useMemo, useEffect, useCallback } from "react";
import { apiReq } from "../../lib/apiReq";
import { useStoreData } from "../../lib/store/store";
import { isLoggedIn } from "../../lib/isLoggedIn";
import { Loader } from "../Loader/Loader";

import AmericanExpress from "./images/AmericanExpress.png";
import MasterCard from "./images/MasterCard.png";
import Visa from "./images/Visa.png";
import Discover from "./images/Discover.png";
import { LinkButton } from "../LinkButton/LinkButton";
import {
  CreditCardForm,
  CreditCardFormErrors,
  CreditCardFormValue,
} from "./CreditCardForm";
import { isCreditCard } from "validator";
import {
  AcceptResponse,
  getANetErrorMessage,
  loadAuthorizeAccept,
} from "../../lib/authorize";

const cardImages = {
  AmericanExpress,
  MasterCard,
  Visa,
  Discover,
};

interface PaymentFormProps extends PaneProps<"div"> {
  submitButtonProps?: ButtonProps;
  onSubmit?: () => void;
}

export const PaymentForm = ({
  submitButtonProps,
  onSubmit,
  ...props
}: PaymentFormProps) => {
  const storeData = useStoreData();
  const [creditCardValue, setCreditCardValue] = useState<CreditCardFormValue>({
    name: "",
    cardNumber: "",
    cvc: "",
    expDate: "",
  });
  const [creditCardErrors, setCreditCardErrors] =
    useState<CreditCardFormErrors>();
  const [editingCard, setEditingCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getCardReq, getCardReqState] = useReq<MaskedCreditCardInfo | null>(
    apiReq
  );
  const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
  const [putCardReq] = useReq<UserWithMediaAndGroups>(apiReq);
  useEffect(() => {
    if (loggedIn) {
      getCardReq.get("/user/card");
    }
  }, [getCardReq, loggedIn]);
  useEffect(() => {
    loadAuthorizeAccept();
  }, []);

  const submit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!editingCard && getCardReqState.result) {
        if (submitButtonProps?.onClick) {
          submitButtonProps.onClick(e);
        }
        if (onSubmit) {
          onSubmit();
        }
        return;
      }
      const finalCardNumber = creditCardValue.cardNumber?.replace(/ /g, "");

      const errors: CreditCardFormErrors = {};

      if (!finalCardNumber) {
        errors.cardNumber = "Please enter your credit card number";
      } else if (!isCreditCard(finalCardNumber)) {
        errors.cardNumber = "Card number invalid";
      }

      if (!creditCardValue.cvc) {
        errors.cardNumber = "Please enter your credit card CVC";
      } else if (!/^[0-9]{3,4}$/.test(creditCardValue.cvc)) {
        errors.cvc = "CVC invalid";
      }
      if (!creditCardValue.name?.length) {
        errors.name = "Name is required";
      }

      const [expMonth, expYear] = (creditCardValue.expDate || "")
        .split("/")
        .map((v) => v.trim());
      if (expMonth.length !== 2 || expYear.length !== 2) {
        errors.expDate = "Epxiration date invalid";
      }

      setCreditCardErrors(errors);
      if (Object.keys(errors).length) {
        return;
      }

      setLoading(true);
      try {
        await loadAuthorizeAccept();
      } catch (error) {
        setCreditCardErrors({
          authorize: "Unable to connect to payment processor",
        });
        setLoading(false);
        return;
      }

      const authData = {
        clientKey: import.meta.env.VITE_ANET_PUBLIC_CLIENT_KEY!,
        apiLoginID: import.meta.env.VITE_ANET_API_LOGIN_ID!,
      };

      const cardData = {
        cardNumber: finalCardNumber,
        month: expMonth,
        year: expYear,
        cardCode: creditCardValue.cvc,
      };

      const secureData = {
        authData,
        cardData,
      };

      const aNetResult: AcceptResponse = await new Promise((resolve) =>
        window.Accept.dispatchData(secureData, resolve)
      );

      if (aNetResult.messages.resultCode.toLowerCase() === "error") {
        setLoading(false);
        setCreditCardErrors({ authorize: getANetErrorMessage(aNetResult) });
        return;
      }

      if (!aNetResult.opaqueData) {
        setLoading(false);
        setCreditCardErrors({
          authorize: "Something went wrong. Please try again.",
        });
        return;
      }

      const response = await putCardReq.put("/user/card", {
        opaqueData: aNetResult.opaqueData,
      });
      setLoading(false);
      if (response.success) {
        if (submitButtonProps?.onClick) {
          submitButtonProps.onClick(e);
        }
        if (onSubmit) {
          onSubmit();
        }
      } else {
        setCreditCardErrors({ authorize: response.error.message });
      }
    },
    [
      creditCardValue.cardNumber,
      creditCardValue.cvc,
      creditCardValue.expDate,
      creditCardValue.name?.length,
      editingCard,
      getCardReqState.result,
      onSubmit,
      putCardReq,
      submitButtonProps,
    ]
  );

  return (
    <Pane {...props}>
      {!loggedIn ? (
        <Paragraph size={500}>
          You must register / log in before entering your credit card
          information.
        </Paragraph>
      ) : getCardReqState.loading ? (
        <Loader />
      ) : getCardReqState.result && !editingCard ? (
        <>
          <Paragraph size={300} marginBottom="0.25rem">
            CURRENT PAYMENT METHOD
          </Paragraph>
          <Pane display="flex" alignItems="center" gap="0.5rem">
            <Pane
              is="img"
              src={cardImages[getCardReqState.result.cardType]}
              height="1.25rem"
            />
            <Pane>{getCardReqState.result.maskedCardNumber}</Pane>
            <LinkButton onClick={() => setEditingCard(true)}>
              Change Credit Card
            </LinkButton>
          </Pane>
        </>
      ) : (
        <CreditCardForm
          onCancel={editingCard ? () => setEditingCard(false) : undefined}
          value={creditCardValue}
          onChange={setCreditCardValue}
          errors={creditCardErrors}
        />
      )}
      {!!submitButtonProps && (
        <Button
          {...submitButtonProps}
          onClick={submit}
          isLoading={loading || submitButtonProps?.isLoading}
        />
      )}
    </Pane>
  );
};
