import { ReqMethod } from "@larner.dev/req";
import { SchemaSection } from "./Schema";
import {
  CMSAttributes,
  CMSData,
  CMSDataList,
  CMSItem,
  CMSMedia,
  CMSMeta,
  RequiredCMSData,
} from "@greenflagdate/shared";
import { useEffect, useState } from "react";
import { cmsRequest } from "./cmsHelpers";

export interface OutcomeAttributes {
  name: string;
  description: string;
  formattedDescription?: string;
  summaryPrefix?: string;
  graphic?: CMSData<CMSMedia>;
  badge?: CMSData<CMSMedia>;
  color?: string;
  profileFormattedDescription: string;
}

interface OptionAttributes extends CMSAttributes {
  label: string;
  outcome?: CMSData<OutcomeAttributes>;
}

export interface QuestionAttributes extends CMSAttributes {
  question: string;
  type: "multiple-choice" | "short-answer";
  explainLabel?: string;
  order: number;
  options?: CMSDataList<OptionAttributes>;
}

interface VideoAttributes extends CMSAttributes {
  video: RequiredCMSData<CMSMedia>;
  thumbnail: RequiredCMSData<CMSMedia>;
}

interface AbstractSectionAttributes extends CMSAttributes {
  name: string;
  subtitle: string;
  type: "video" | "questions";
  slug: string;
  order: number;
  private: boolean;
  badge?: CMSData<CMSMedia>;
  profileName?: string;
}

export interface VideoSectionAttributes extends AbstractSectionAttributes {
  type: "video";
  video: RequiredCMSData<VideoAttributes>;
}

export interface QuestionsSectionAttributes extends AbstractSectionAttributes {
  type: "questions";
  questions: CMSDataList<QuestionAttributes>;
}

export type SectionAttributes =
  | VideoSectionAttributes
  | QuestionsSectionAttributes;

export interface ModuleAttributes extends CMSAttributes {
  order: number;
  slug: string;
  name: string;
  sections: CMSDataList<SectionAttributes>;
}

export interface QuizData extends CMSDataList<ModuleAttributes> {
  meta: CMSMeta;
}

export const quizDataPromise: Promise<QuizData> = cmsRequest[
  ReqMethod.GET
]<QuizData>(
  "/api/modules?populate[0]=sections&populate[1]=sections.video&populate[2]=sections.video.video&populate[3]=sections.badge&populate[4]=sections.video.thumbnail&populate[5]=sections.questions&populate[6]=sections.questions.options&populate[7]=sections.questions.options.outcome&populate[8]=sections.questions.options.outcome.badge&populate[9]=sections.questions.options.outcome.graphic"
).then((quizData: QuizData) => {
  quizData.data = quizData.data
    .filter((m) => m.id !== 1)
    .sort((a, b) => a.attributes.order - b.attributes.order);
  for (const m of quizData.data) {
    m.attributes.sections.data = m.attributes.sections.data.sort(
      (a, b) => a.attributes.order - b.attributes.order
    );
  }
  return quizData;
});

let quizDataPromiseResolved = false;
export const useQuizData = () => {
  const [state, setState] = useState<{
    loading: boolean;
    quizData: QuizData | null;
  }>({
    loading: !quizDataPromiseResolved,
    quizData: null,
  });
  useEffect(() => {
    quizDataPromise.then((quizData) => {
      quizDataPromiseResolved = true;
      setState({ loading: false, quizData });
    });
  }, []);
  return state;
};

export const getWelcomeModule = (quizData: QuizData) => {
  return quizData.data.find((m) => m.attributes.order === 0)!;
};

export const getModule = (
  quizData: QuizData,
  moduleIdOrSlug: string | number
) => {
  return quizData.data.find((m) =>
    [m.id, m.attributes.slug].includes(moduleIdOrSlug)
  )!;
};

export const getSection = (
  quizData: QuizData,
  moduleIdOrSlug: string | number,
  sectionIdOrSlug: string | number
) => {
  return getModule(quizData, moduleIdOrSlug).attributes.sections.data.find(
    (s) => [s.id, s.attributes.slug].includes(sectionIdOrSlug)
  )!;
};

export const getQuestion = (
  quizData: QuizData,
  moduleIdOrSlug: string | number,
  sectionIdOrSlug: string | number,
  questionId: number
) => {
  const questionSection = getSection(
    quizData,
    moduleIdOrSlug,
    sectionIdOrSlug
  ) as CMSItem<QuestionsSectionAttributes>;
  return questionSection.attributes.questions.data.find(
    (q) => q.id === questionId
  )!;
};

export const isVideoSection = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any
): x is CMSItem<VideoSectionAttributes> => {
  return x.attributes?.type === "video" && !!x.attributes?.video?.data?.id;
};

export const isQuestionSection = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any
): x is CMSItem<QuestionsSectionAttributes> => {
  return x.attributes?.type === "questions";
};

export const getOutcome = (
  cmsSection: CMSItem<QuestionsSectionAttributes>,
  section: SchemaSection
): CMSItem<OutcomeAttributes> | string | undefined => {
  if (section.outcomeId) {
    for (const q of cmsSection.attributes.questions.data) {
      if (
        q.attributes.type === "multiple-choice" &&
        q.attributes.options?.data
      ) {
        for (const o of q.attributes.options.data) {
          if (o.attributes.outcome?.data?.id === section.outcomeId) {
            return o.attributes.outcome?.data;
          }
        }
      }
    }
  }
  const questions = section.questions || new Map();
  const outcomes = new Map<number, CMSItem<OutcomeAttributes>>();
  const multiChoiceQuestions = cmsSection.attributes.questions.data.filter(
    (q) => q.attributes.type === "multiple-choice"
  );

  const agg = new Map<number, number>();

  for (const q of multiChoiceQuestions) {
    const answer = questions.get(q.id);
    if (!answer) {
      return cmsSection.attributes.slug;
    } else {
      const outcome = q.attributes.options?.data.find(
        (o) => o.id === answer.answerId
      );
      if (outcome?.attributes.outcome?.data) {
        agg.set(outcome.id, (agg.get(outcome.id) || 0) + 1);
        outcomes.set(outcome.id, outcome.attributes.outcome.data);
      }
    }
  }

  const sorted = Array.from(agg).sort((a, b) => b[1] - a[1]);
  return sorted.length ? outcomes.get(sorted[0][0])! || undefined : undefined;
};
