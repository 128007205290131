import classNames from "classnames";
import { Link, useOutletContext, useParams } from "react-router-dom";
import posthog from "posthog-js";
import { SchemaData, SchemaSection } from "../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  ModuleAttributes,
  SectionAttributes,
  isVideoSection,
  QuizData,
  getModule,
} from "../../../lib/quizDataHelpers";
import { Button } from "../../../components/Button/Button";

import styles from "./DiscoverModule.module.css";

import checkImage from "./images/check.png";
import { Meta } from "../../../components/Meta/Meta";
import {
  faChevronRight,
  faCircleQuestion,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotFound } from "../../../components/NotFound/NotFound";
import { DiscoverSteps } from "./DiscoverSteps";
import { ActionPanel } from "../../../components/ActionPanel/ActionPanel";
import { SectionVideo } from "./SectionVideo";
import { SaveButton } from "../../../components/SaveButton/SaveButton";

interface ModuleSectionProps {
  module: CMSItem<ModuleAttributes>;
  section: CMSItem<SectionAttributes>;
  sectionData?: SchemaSection;
  before?: CMSItem<SectionAttributes>;
  beforeData?: SchemaSection;
  after?: CMSItem<SectionAttributes>;
  afterData?: SchemaSection;
}

function Section({
  module: m,
  section,
  sectionData,
  before,
  beforeData,
  after,
  afterData,
}: ModuleSectionProps) {
  const disabled = before && !beforeData?.completed;

  return (
    <div className={styles.section}>
      <div className={styles.sectionStatus}>
        <div
          className={classNames(styles.sectionLine, styles.top, {
            [styles.hidden]: !before,
            [styles.disabled]: !beforeData?.completed,
          })}
        />

        <section
          className={classNames(styles.sectionCircle, {
            [styles.completed]: sectionData?.completed,
            [styles.disabled]: disabled,
          })}
        >
          {sectionData?.completed && (
            <img
              src={checkImage}
              alt="Completed"
              className={styles.completedImage}
            />
          )}
          {disabled && (
            <FontAwesomeIcon
              icon={faLock}
              className={styles.lock}
              color="var(--color-gray-500)"
              style={{ fontSize: "22px" }}
            />
          )}
        </section>

        <div
          className={classNames(styles.sectionLine, styles.bottom, {
            [styles.hidden]: !after,
            [styles.disabled]: !afterData?.completed,
          })}
        />
      </div>
      {isVideoSection(section) ? (
        <SectionVideo
          module={m}
          section={section}
          startTime={sectionData?.videoStats?.currentTime || 0}
          disabled={disabled}
        />
      ) : (
        <Link
          className={classNames(styles.sectionContent, {
            [styles.disabled]: disabled,
          })}
          to={`/${mvpSlug}/${m.attributes.slug}/${section.attributes.slug}`}
          aria-disabled={disabled}
          onClick={() =>
            posthog.capture("sectionClick", {
              type: section.attributes.type,
              id: section.id,
            })
          }
        >
          <div className={styles.icon}>
            <FontAwesomeIcon
              icon={faCircleQuestion}
              color="var(--color-primary-800)"
              style={{ fontSize: "40px" }}
            />
          </div>
          <div className={styles.caption}>
            <div className={styles.title}>{section.attributes.name}</div>
            <div className={styles.length}>{section.attributes.subtitle}</div>
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            className={classNames({ [styles.disabledChevron]: disabled })}
          />
        </Link>
      )}
    </div>
  );
}

export function DiscoverModule() {
  const { quizData, schemaData } = useOutletContext<{
    quizData: QuizData;
    schemaData: SchemaData;
  }>();
  const { moduleSlug } = useParams();
  const m = getModule(quizData, moduleSlug || "");
  if (!moduleSlug || !m) {
    return <NotFound />;
  }
  const moduleData = schemaData.modules.get(m.id);

  return (
    <>
      <Meta>
        <title>{m.attributes.name} - Discover your Green Flags</title>
      </Meta>
      <DiscoverSteps
        currentPageSlug={moduleSlug}
        store={schemaData}
        quizData={quizData}
      />
      {moduleData?.completed && (
        <ActionPanel>
          <div className={styles.completedPanelContent}>
            {m.id === quizData.data[quizData.data.length - 2].id ? (
              <>
                <div>Congratulations on discovering</div>
                <div>your green flags!</div>
              </>
            ) : (
              <div>{`Congratulations! You finished Section ${m.attributes.order}!`}</div>
            )}
            {m.id !== quizData.data[quizData.data.length - 2].id && (
              <div>🎉🎉 Keep up the great work! 🎉🎉 </div>
            )}

            <Button
              label={
                m.id === quizData.data[quizData.data.length - 2].id
                  ? "View My Green Flags"
                  : "Next Section"
              }
              onClick={() =>
                posthog.capture("nextSectionClick", {
                  text:
                    m.id === quizData.data[quizData.data.length - 2].id
                      ? "View My Green Flags"
                      : "Next Section",
                })
              }
              size="large"
              fullWidth
              className={styles.nextModuleButton}
              href={`/${mvpSlug}/${
                quizData.data.find(
                  (mod) => mod.attributes.order === m.attributes.order + 1
                )?.attributes.slug
              }`}
            />
          </div>
        </ActionPanel>
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerName}>{m.attributes.name}</div>
          <SaveButton
            user={schemaData.user}
            onClick={() =>
              posthog.capture("saveButtonClick", {
                source: "DiscoverModule",
              })
            }
          />
        </div>
        {m.attributes.sections.data.map((s, i) => (
          <Section
            key={i}
            module={m}
            section={s}
            sectionData={schemaData.modules.get(m.id)?.sections.get(s.id)}
            before={m.attributes.sections.data[i - 1]}
            beforeData={schemaData.modules
              .get(m.id)
              ?.sections.get(m.attributes.sections.data[i - 1]?.id)}
            after={m.attributes.sections.data[i + 1]}
            afterData={schemaData.modules
              .get(m.id)
              ?.sections.get(m.attributes.sections.data[i + 1]?.id)}
          />
        ))}
      </div>
    </>
  );
}
