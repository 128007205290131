import classNames from "classnames";
import styles from "./PlayIcon.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

interface PlayIconProps {
  className?: string;
  hover?: boolean;
  size: number;
}

export function PlayIcon({ size, hover, className }: PlayIconProps) {
  const sizepx = `${size}px`;
  return (
    <div className={styles.overlay}>
      <div
        className={classNames(styles.container, className, {
          [styles.hover]: hover,
        })}
        style={{ width: sizepx, height: sizepx }}
      >
        <FontAwesomeIcon
          icon={faPlay}
          style={{ fontSize: "24px" }}
          className={styles.icon}
          color="var(--color-white)"
          fill="var(--color-white)"
        />
      </div>
    </div>
  );
}
