import posthog from "posthog-js";
import { UserWithMediaAndProfile } from "../../../lib/Schema";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import { ModuleAttributes } from "../../../lib/quizDataHelpers";
import { BackLink } from "../../../components/BackLink/BackLink";
import { SaveButton } from "../QuizModule/SaveButton";

import styles from "./DiscoverQuestionHeader.module.css";

export interface QuizSectionHeaderProps {
  module: CMSItem<ModuleAttributes>;
  user?: UserWithMediaAndProfile;
}

export function QuizSectionHeader({ module: m, user }: QuizSectionHeaderProps) {
  return (
    <div className={styles.header}>
      <BackLink href={`/${mvpSlug}/${m.attributes.slug}`} />
      <div>{m.attributes.name}</div>
      <SaveButton
        user={user}
        onClick={() =>
          posthog.capture("saveButtonClick", {
            source: "QuizSectionHeader",
          })
        }
      />
    </div>
  );
}
