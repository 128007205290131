import { useEffect, useState } from "react";

export interface Accept {
  dispatchData: (
    data: AcceptSecureData,
    callback: (response: AcceptResponse) => void
  ) => void;
}

// Enum for the response codes
export enum AcceptJsResponseCode {
  I_WC_01 = "I_WC_01",
  E_WC_01 = "E_WC_01",
  E_WC_02 = "E_WC_02",
  E_WC_03 = "E_WC_03",
  E_WC_04 = "E_WC_04",
  E_WC_05 = "E_WC_05",
  E_WC_06 = "E_WC_06",
  E_WC_07 = "E_WC_07",
  E_WC_08 = "E_WC_08",
  E_WC_10 = "E_WC_10",
  E_WC_13 = "E_WC_13",
  E_WC_14 = "E_WC_14",
  E_WC_15 = "E_WC_15",
  E_WC_16 = "E_WC_16",
  E_WC_17 = "E_WC_17",
  E_WC_18 = "E_WC_18",
  E_WC_19 = "E_WC_19",
  E_WC_20 = "E_WC_20",
  E_WC_21 = "E_WC_21",
  E_WC_23 = "E_WC_23",
  E_WC_24 = "E_WC_24",
  E_WC_25 = "E_WC_25",
  E_WC_26 = "E_WC_26",
  E_WC_27 = "E_WC_27",
}

// Object mapping the enum to the response text
const AcceptJsResponseMessages: { [key in AcceptJsResponseCode]: string } = {
  [AcceptJsResponseCode.I_WC_01]: "Successful.",
  [AcceptJsResponseCode.E_WC_01]: "Please include Accept.js library from CDN.",
  [AcceptJsResponseCode.E_WC_02]: "A HTTPS connection is required.",
  [AcceptJsResponseCode.E_WC_03]: "Accept.js is not loaded correctly.",
  [AcceptJsResponseCode.E_WC_04]: "Please provide mandatory field to library.",
  [AcceptJsResponseCode.E_WC_05]: "Please provide valid credit card number.",
  [AcceptJsResponseCode.E_WC_06]: "Please provide valid expiration month.",
  [AcceptJsResponseCode.E_WC_07]: "Please provide valid expiration year.",
  [AcceptJsResponseCode.E_WC_08]: "The expiration date must be in the future.",
  [AcceptJsResponseCode.E_WC_10]: "Please provide valid apiLoginID.",
  [AcceptJsResponseCode.E_WC_13]: "Invalid Fingerprint.",
  [AcceptJsResponseCode.E_WC_14]: "Accept.js encryption failed.",
  [AcceptJsResponseCode.E_WC_15]: "Please provide valid CVV.",
  [AcceptJsResponseCode.E_WC_16]: "Please provide valid ZIP code.",
  [AcceptJsResponseCode.E_WC_17]: "Please provide valid card holder name.",
  [AcceptJsResponseCode.E_WC_18]: "Client Key is required.",
  [AcceptJsResponseCode.E_WC_19]:
    "An error occurred during processing. Please try again.",
  [AcceptJsResponseCode.E_WC_20]: "The credit card number is too long.",
  [AcceptJsResponseCode.E_WC_21]:
    "User authentication failed due to invalid authentication values.",
  [AcceptJsResponseCode.E_WC_23]:
    "Please provide either card information or bank information.",
  [AcceptJsResponseCode.E_WC_24]: "Please provide valid account number.",
  [AcceptJsResponseCode.E_WC_25]: "Please provide valid routing number.",
  [AcceptJsResponseCode.E_WC_26]: "Please provide valid account holder name.",
  [AcceptJsResponseCode.E_WC_27]: "Please provide valid account type.",
};

export interface AcceptSecureData {
  authData: {
    clientKey: string;
    apiLoginID: string;
  };
  cardData?: {
    cardNumber: string;
    month: string;
    year: string;
    cardCode: string;
  };
  bankData?: {
    routingNumber: string;
    accountNumber: string;
    nameOnAccount: string;
    accountType: string;
    echeckType: string;
  };
}

export interface AcceptResponse {
  messages: {
    resultCode: string;
    message: Array<{
      code: AcceptJsResponseCode;
      text: string;
    }>;
  };
  opaqueData?: {
    dataDescriptor: string;
    dataValue: string;
  };
}

export const loadAuthorizeAccept = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const existingScript = document.getElementById("accept-js");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = import.meta.env.VITE_ANET_ACCEPTJS_URL;
      script.id = "accept-js";
      script.type = "text/javascript";
      script.charset = "utf-8";
      script.async = true;
      script.onload = () => {
        resolve();
      };
      script.onerror = () =>
        reject(new Error("Failed to load the Accept.js script."));
      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
};

export const useLoadAccept = () => {
  const [state, setState] = useState({
    loading: !document.getElementById("accept-js"),
    error: "",
  });
  useEffect(() => {
    loadAuthorizeAccept()
      .then(() => {
        setState({ loading: false, error: "" });
      })
      .catch((error) => ({ loading: false, error: error.toString() }));
  }, []);
  return state;
};

export const getANetErrorMessage = (res: AcceptResponse) => {
  if (res.messages.resultCode.toLowerCase() === "error") {
    if (res.messages.message[0].code in AcceptJsResponseMessages) {
      return AcceptJsResponseMessages[res.messages.message[0].code];
    }
  }
  return "Unknown credit card processing error.";
};
