import { useEffect } from "react";
import styles from "./Events.module.css";
import { Loader } from "../../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Link as StyledLink } from "../../../components/Link/Link";
import { Meta } from "../../../components/Meta/Meta";
import dayjs from "dayjs";
import { H2 } from "../../../components/Heading/Heading";
import { Pane } from "evergreen-ui";
import Markdown from "react-markdown";
import { useReq } from "@larner.dev/use-req";
import { cmsRequest } from "../../../lib/cmsHelpers";
import {
  CMSEventAttributes,
  CMSEventData,
  CMSItem,
  Spark,
} from "@greenflagdate/shared";
import { useSparkDates } from "../../../lib/useSparkDates";
import { useGFTheme } from "../../../lib/themes";
import { getCurrentSparkPage } from "../../../lib/getCurrentSparkPage";
import { apiReq } from "../../../lib/apiReq";

enum ItemType {
  Spark = "spark",
  Event = "event",
}
interface AbstractItem {
  type: ItemType;
}

interface SparkItem extends AbstractItem {
  type: ItemType.Spark;
  data: Spark;
}

interface EventItem extends AbstractItem {
  type: ItemType.Event;
  data: CMSItem<CMSEventAttributes>;
}

type Item = SparkItem | EventItem;

export const Events = () => {
  const navigate = useNavigate();
  const [request, requestState] = useReq<CMSEventData>(cmsRequest);
  const [sparksReq, sparksReqState] = useReq<Spark[]>(apiReq);
  const { colors } = useGFTheme();
  const sparkDates = useSparkDates();
  useEffect(() => {
    request.get(
      `/api/events?filters[registrationEndsAt][$gte]=${new Date().toISOString()}&populate[0]=socialShareImage`
    );
    sparksReq.get(`/spark/current`);
  }, [request, sparksReq]);

  if (
    !requestState.result ||
    requestState.loading ||
    !sparksReqState.result ||
    sparksReqState.loading
  ) {
    return (
      <div className={styles.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (!requestState.loading) {
    if (requestState.error) {
      return <div>{requestState.error.toString()}</div>;
    }
  }
  if (!sparksReqState.loading) {
    if (sparksReqState.error) {
      return <div>{sparksReqState.error.toString()}</div>;
    }
  }

  const data: Item[] = [
    ...requestState.result.data.map(
      (event): EventItem => ({
        type: ItemType.Event,
        data: event,
      })
    ),
    ...sparksReqState.result.map(
      (spark): SparkItem => ({
        type: ItemType.Spark,
        data: spark,
      })
    ),
  ].sort((a, b) => {
    const aEnds = new Date(
      a.type === ItemType.Event ? a.data.attributes.endsAt : a.data.ends_at
    ).getTime();
    const bEnds = new Date(
      b.type === ItemType.Event ? b.data.attributes.endsAt : b.data.ends_at
    ).getTime();
    return bEnds - aEnds;
  });

  return (
    <>
      <Meta>
        <title>Green Flag Events</title>
        <meta property="og:title" content="Green Flag Events" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.greenflagdate.com/events"
        />
      </Meta>
      <Pane maxWidth="40rem" marginX="auto" marginTop="3rem" paddingX="2rem">
        {data.length ? (
          <>
            <Pane marginBottom="2rem">
              All Events are currently in Atlanta. If you are interested in
              hosting an event in your city please{" "}
              <StyledLink to="/contact">let us know!</StyledLink>
            </Pane>
            <Pane
              marginBottom="1rem"
              padding="0.5rem"
              backgroundColor={colors.blueLight}
              color="white"
              border="0"
              borderBottom="2px solid black"
              fontSize="14px"
              cursor="pointer"
              selectors={{
                "&:hover": {
                  background: colors.bluePrimary,
                },
              }}
              onClick={() => navigate(getCurrentSparkPage())}
            >
              Interested in more events? Try a Spark! Sign ups now open for{" "}
              <strong>
                {sparkDates[0].format("MMMM D")} -{" "}
                {sparkDates[sparkDates.length - 1].format("MMMM D")}
              </strong>
            </Pane>
            {data.map((event) =>
              event.type === ItemType.Event ? (
                <Link
                  className={styles.event}
                  to={`/event/${event.data.attributes.slug}`}
                  key={event.data.id}
                >
                  <div className={styles.imageHolder}>
                    {event.data.attributes.socialShareImage?.data && (
                      <img
                        src={
                          event.data.attributes.socialShareImage.data.attributes
                            .url
                        }
                        alt={`${event.data.attributes.title} image`}
                        className={styles.socialShareImage}
                        width={
                          event.data.attributes.socialShareImage.data.attributes
                            .width
                        }
                        height={
                          event.data.attributes.socialShareImage.data.attributes
                            .height
                        }
                      />
                    )}
                  </div>
                  <div className={styles.eventContent}>
                    <Pane marginBottom="0.5rem">
                      Registration closes{" "}
                      {dayjs(event.data.attributes.registrationEndsAt).format(
                        "ll"
                      )}
                    </Pane>
                    <H2 marginBottom="0.5rem">{event.data.attributes.title}</H2>
                    <Pane marginBottom="0.5rem">
                      <Markdown allowedElements={[]} unwrapDisallowed>
                        {event.data.attributes.subtitle}
                      </Markdown>
                    </Pane>
                    <div className={styles.readMore}>Click for details...</div>
                  </div>
                </Link>
              ) : (
                <Link
                  className={styles.event}
                  to={`/spark/atlanta/${event.data.id}`}
                  key={event.data.id}
                >
                  <div className={styles.imageHolder}>
                    {/* {event.data.attributes.socialShareImage?.data && (
                      <img
                        src={
                          event.data.attributes.socialShareImage.data.attributes
                            .url
                        }
                        alt={`${event.data.attributes.title} image`}
                        className={styles.socialShareImage}
                        width={
                          event.data.attributes.socialShareImage.data.attributes
                            .width
                        }
                        height={
                          event.data.attributes.socialShareImage.data.attributes
                            .height
                        }
                      />
                    )} */}
                  </div>
                  <div className={styles.eventContent}>
                    <Pane marginBottom="0.5rem">
                      Registration closes{" "}
                      {dayjs(event.data.starts_at).format("ll")}
                    </Pane>
                    <H2 marginBottom="0.5rem">{event.data.title}</H2>
                    <Pane marginBottom="0.5rem">
                      <Markdown allowedElements={[]} unwrapDisallowed>
                        {event.data.location}
                      </Markdown>
                    </Pane>
                    <div className={styles.readMore}>Click for details...</div>
                  </div>
                </Link>
              )
            )}
          </>
        ) : (
          <>
            <Pane
              marginBottom="1rem"
              padding="0.5rem"
              backgroundColor={colors.blueLight}
              color="white"
              border="0"
              borderBottom="2px solid black"
              fontSize="14px"
              cursor="pointer"
              selectors={{
                "&:hover": {
                  background: colors.bluePrimary,
                },
              }}
              onClick={() => navigate(getCurrentSparkPage())}
            >
              Interested in more events? Try a Spark! Sign ups now open for{" "}
              <strong>
                {sparkDates[0].format("MMMM D")} -{" "}
                {sparkDates[sparkDates.length - 1].format("MMMM D")}
              </strong>
            </Pane>
            <Pane marginBottom="2rem">
              We don't have any events scheduled at this time. If you are
              interested in hosting an event in your city please{" "}
              <StyledLink to="/contact">let us know!</StyledLink>
            </Pane>
          </>
        )}
      </Pane>
    </>
  );
};
