import classNames from "classnames";
import { useMemo, useRef } from "react";
import styles from "./Profile.module.css";
import { ProfileReadSection } from "./ProfileReadSection";
import {
  PublicUserWithProfile,
  reviver,
  SchemaModule,
  SchemaSection,
} from "../../../lib/Schema";
import { CMSItem, PublicUser } from "@greenflagdate/shared";
import {
  getOutcome,
  QuestionsSectionAttributes,
  SectionAttributes,
  ModuleAttributes,
  OutcomeAttributes,
  useQuizData,
} from "../../../lib/quizDataHelpers";
import { useSchemaStore } from "../../../lib/useSchemaStore";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotFound } from "../../../components/NotFound/NotFound";
import trim from "lodash.trim";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Meta } from "../../../components/Meta/Meta";
import posthog from "posthog-js";
import { Button } from "../../../components/Button/Button";
import { QuizActionPanel } from "../QuizModule/QuizActionPanel";
import { apiReq } from "../../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";

export function Profile() {
  const [request, requestState] = useReq<PublicUser>(apiReq);
  const data = useSchemaStore();
  const fetchingUser = useRef(false);
  const location = useLocation();
  const { quizData } = useQuizData();

  const [userSlug, sectionSlug] = trim(location.pathname, "/").split("/");

  const user: PublicUserWithProfile | false = useMemo(() => {
    if (requestState.result) {
      if (!requestState.error) {
        posthog.capture("publicProfileView", {
          slug: requestState.result.slug,
        }),
          {
            $set_once: { referrer: requestState.result.slug },
          };
      }

      return {
        ...requestState.result,
        profile: JSON.parse(requestState.result?.profile, reviver) as Map<
          number,
          SchemaModule
        >,
      };
    }
    if (!requestState.loading && !requestState.error && !fetchingUser.current) {
      fetchingUser.current = true;
      request.get(`/user/${userSlug}`);
    }
    return false;
  }, [
    request,
    requestState.error,
    requestState.loading,
    requestState.result,
    userSlug,
  ]);

  const sections = useMemo(
    () =>
      quizData !== null &&
      user &&
      Array.from(user.profile.entries())
        .reduce(
          (agg, [moduleId, mod]) => {
            const sections = Array.from(mod.sections.entries()).map(
              ([sectionId, section]) => {
                const quizModule = quizData.data.find((m) => m.id === moduleId);
                const quizSection = quizModule?.attributes.sections.data.find(
                  (s) => s.id === sectionId
                );
                const outcome = quizSection
                  ? getOutcome(
                      quizSection as CMSItem<QuestionsSectionAttributes>,
                      section
                    )
                  : undefined;
                return {
                  section,
                  quizSection,
                  quizModule,
                  outcome: typeof outcome === "string" ? undefined : outcome,
                };
              }
            );
            return [...agg, ...sections];
          },
          [] as {
            section: SchemaSection;
            quizSection: CMSItem<SectionAttributes> | undefined;
            quizModule: CMSItem<ModuleAttributes> | undefined;
            outcome: CMSItem<OutcomeAttributes> | undefined;
          }[]
        )
        .filter(
          (d) =>
            d.quizSection &&
            d.quizSection.attributes.type === "questions" &&
            !d.quizSection.attributes.private
        )
        .sort((a, b) =>
          b.outcome?.attributes.badge?.data &&
          !a.outcome?.attributes.badge?.data
            ? 1
            : 0
        ),
    [quizData, user]
  );

  const datingApproach = (sections || []).find((s) => s.section.id === 3);
  const communicationStyle = (sections || []).find((s) => s.section.id === 9);
  const connectionStyle = (sections || []).find((s) => s.section.id === 7);

  if (user && sections && sectionSlug) {
    const section = sections.find(
      (s) => s.quizSection?.attributes.slug === sectionSlug.toLowerCase()
    );
    if (
      section &&
      section.quizSection &&
      section.quizSection.attributes.type === "questions" &&
      section.quizModule
    ) {
      return (
        <ProfileReadSection
          quizSection={
            section.quizSection as CMSItem<QuestionsSectionAttributes>
          }
          outcome={section.outcome}
          quizModule={section.quizModule}
          user={user}
        />
      );
    }
  }
  return (
    <>
      {!!user && (
        <Meta>
          <title>{user.first_name}&apos;s Green Flags</title>
        </Meta>
      )}

      {!!user && data.loaded && !data.token && (
        <QuizActionPanel className={styles.profileAlert}>
          <div className={styles.cta}>
            <strong>Get high quality matches.</strong>
            <br />
            Discover your own green flags.
            <Button
              label="Get Started"
              size="medium"
              href="/discover/stability"
              className={styles.getStartedButton}
              onClick={() =>
                posthog.capture("profileCtaClick", {
                  text: "Get Started",
                  slug: user.slug,
                })
              }
            />
          </div>
        </QuizActionPanel>
      )}

      {!!user && sections && (
        <div className={styles.container}>
          {!!user && !!data.user && user.id === data.user.id && (
            <div className={styles.editLinks}>
              <Link to="/discover/stability" className={styles.editLink}>
                edit my answers
              </Link>{" "}
              |&nbsp;
              <Link to="/discover/my-green-flags" className={styles.editLink}>
                edit privacy
              </Link>
            </div>
          )}
          {!!requestState.error && <NotFound />}
          {(!!datingApproach?.outcome ||
            !!communicationStyle?.outcome ||
            !!connectionStyle?.outcome) && (
            <div className={styles.summary}>
              <div className={styles.name}>
                {user.first_name?.trim()}&apos;s Green Flags
              </div>
              {!!datingApproach?.outcome && (
                <div
                  className={classNames(
                    styles.greenFlagResult,
                    styles.datingApproach
                  )}
                  style={{
                    backgroundColor: datingApproach?.outcome?.attributes.color,
                  }}
                >
                  <div className={styles.greenFlagResultLabel}>
                    Dating Approach
                  </div>
                  <div className={styles.greenFlagResultValue}>
                    {datingApproach?.outcome?.attributes.name}
                  </div>
                </div>
              )}
              {!!communicationStyle?.outcome && (
                <div
                  className={classNames(
                    styles.greenFlagResult,
                    styles.communicationStyle
                  )}
                  style={{
                    backgroundColor:
                      communicationStyle?.outcome?.attributes.color,
                  }}
                >
                  <div className={styles.greenFlagResultLabel}>
                    Communication Style
                  </div>
                  <div className={styles.greenFlagResultValue}>
                    {communicationStyle?.outcome?.attributes.name}
                  </div>
                </div>
              )}
              {!!connectionStyle?.outcome && (
                <div
                  className={classNames(
                    styles.greenFlagResult,
                    styles.connectionStyle
                  )}
                  style={{
                    backgroundColor: connectionStyle?.outcome?.attributes.color,
                  }}
                >
                  <div className={styles.greenFlagResultLabel}>
                    Connection Style
                  </div>
                  <div className={styles.greenFlagResultValue}>
                    {connectionStyle?.outcome?.attributes.name}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className={styles.promote}>
            Through the Green Flag process, {user.first_name} has shown a
            genuine interest in personal and relational growth and has a strong
            foundation for building a healthy, fulfilling, and lasting
            connection.
          </div>
          <div className={styles.sectionRows}>
            {sections.map((s) => {
              return (
                <Link
                  key={s.quizSection?.id}
                  to={`/${user.slug}/${s.quizSection?.attributes.slug}`}
                  className={styles.sectionRow}
                >
                  {s.outcome?.attributes.badge?.data && (
                    <img
                      src={s.outcome.attributes.badge.data.attributes.url}
                      alt={`${s.outcome.attributes.name} badge`}
                      className={styles.badge}
                      width={s.outcome.attributes.badge.data.attributes.width}
                      height={s.outcome.attributes.badge.data.attributes.height}
                    />
                  )}
                  {!s.outcome?.attributes.badge?.data &&
                    s.quizSection?.attributes.badge?.data && (
                      <img
                        src={
                          s.quizSection?.attributes.badge?.data.attributes.url
                        }
                        alt={`${s.quizSection.attributes.name} badge`}
                        className={styles.badge}
                        width={
                          s.quizSection?.attributes.badge?.data.attributes.width
                        }
                        height={
                          s.quizSection?.attributes.badge?.data.attributes
                            .height
                        }
                      />
                    )}
                  <div className={styles.sectionRowContent}>
                    {s.outcome?.attributes.badge?.data && (
                      <div className={styles.sectionRowLabel}>
                        {s.quizSection?.attributes.profileName ||
                          s.quizSection?.attributes.name}
                      </div>
                    )}
                    <div className={styles.sectionRowValue}>
                      {s.outcome?.attributes.badge?.data
                        ? s.outcome.attributes.name
                        : (s.quizSection?.attributes.profileName &&
                            `${user.first_name}'s ${s.quizSection?.attributes.profileName}`) ||
                          s.quizSection?.attributes.name}
                    </div>
                  </div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
