import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import posthog from "posthog-js";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  QuizData,
  getModule,
  getSection,
  QuestionsSectionAttributes,
  getQuestion,
  getOutcome,
} from "../../../lib/quizDataHelpers";
import { schemaStore } from "../../../lib/schemaStore";
import { Button } from "../../../components/Button/Button";
import { TextField } from "../../../components/TextField/TextField";
import { Meta } from "../../../components/Meta/Meta";
import { Card } from "../../../components/Card/Card";
import { QuizSectionHeader } from "./DiscoverQuestionHeader";

import styles from "./DiscoverQuestion.module.css";
import { MultipleChoice } from "../../../components/MultipleChoice/MultipleChoice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { SchemaData } from "../../../lib/Schema";

export interface QuizQuestionPageProps {
  moduleSlug: string;
  sectionSlug: string;
  questionId: number;
  quizData: QuizData;
}

export function DiscoverQuestion() {
  const { moduleSlug, sectionSlug, questionId } = useParams();
  const mSlug = moduleSlug || "";
  const sSlug = sectionSlug || "";
  const qId = parseInt(questionId || "0") || 0;
  const { quizData, schemaData } = useOutletContext<{
    quizData: QuizData;
    schemaData: SchemaData;
  }>();
  const navigate = useNavigate();
  const m = getModule(quizData, mSlug || "");
  const s = getSection(
    quizData,
    mSlug || "",
    sSlug || ""
  ) as CMSItem<QuestionsSectionAttributes>;
  const q = getQuestion(quizData, mSlug, sSlug, qId);
  const questionIndex = s.attributes.questions.data.findIndex(
    (q) => q.id === qId
  );
  const sec = schemaData.modules.get(m.id)?.sections.get(s.id);
  const answer = sec?.questions?.get(q.id);

  return (
    <>
      <Meta>
        <title>
          {`${s.attributes.name} - Question ${
            questionIndex + 1
          } - Discover your Green Flags`}
        </title>
      </Meta>
      <QuizSectionHeader module={m} user={schemaData.user} />
      <div className={styles.questionContainer}>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Card.Header>{q.attributes.question}</Card.Header>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {m && s && q && (
              <>
                {q.attributes.type === "multiple-choice" && (
                  <MultipleChoice
                    className={styles.questionForm}
                    options={q.attributes.options!.data.map((d) => ({
                      label: d.attributes.label,
                      id: d.id,
                    }))}
                    value={answer?.answerId}
                    onChange={(option) =>
                      schemaStore.updateQuestion(m, s, q.id, {
                        answerId:
                          answer?.answerId === option.id
                            ? undefined
                            : option.id,
                      })
                    }
                  />
                )}
                {q.attributes.type === "short-answer" && (
                  <div className={styles.textFieldContainer}>
                    <TextField
                      fullWidth
                      multiLine
                      placeholder={q.attributes.explainLabel}
                      value={answer?.explain || ""}
                      onChange={(e) => {
                        schemaStore.updateQuestion(m, s, q.id, {
                          explain: e.target.value,
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {m &&
              s &&
              q &&
              q.attributes.type !== "short-answer" &&
              !!q.attributes.explainLabel && (
                <div className={styles.textFieldContainer}>
                  <TextField
                    className={styles.explainBox}
                    placeholder={q.attributes.explainLabel}
                    value={answer?.explain || ""}
                    fullWidth
                    multiLine
                    onChange={(e) =>
                      schemaStore.updateQuestion(m, s, q.id, {
                        explain: e.target.value,
                      })
                    }
                  />
                </div>
              )}
            {s.attributes.private && (
              <div className={styles.private}>
                <FontAwesomeIcon
                  icon={faLock}
                  style={{ marginRight: "1rem" }}
                />
                <div>
                  <div className={styles.visibilityLabel}>VISIBILITY</div>
                  <div className={styles.visibilityInfo}>
                    This is a private question.
                  </div>
                  <div className={styles.visibilityInfo}>
                    Answers are viewable only by you.
                  </div>
                </div>
              </div>
            )}
            {/* {!s.attributes.private && (
              <div className={styles.privacy}>
                <div className={styles.visibilityLabel}>
                  VISIBILITY
                </div>

                <LockSwitch
                  size="medium"
                  checked={!!answer?.public}
                  onChange={(pub) => {
                    posthog.capture("privacyChange", {
                
                        type: "question",
                        id: q.id,
                        source: "QuizQuestionPage",
                        value: pub,
                    
                    });
                    schemaStore.updateQuestion(m, s, q.id, {
                      public: pub,
                    });
                  }}
                />
              </div>
            )} */}

            <div className={styles.buttons}>
              <Button
                label={questionIndex === 0 ? "Back" : "Previous"}
                size="medium"
                outline
                onClick={() => {
                  if (questionIndex === 0) {
                    navigate(`/${mvpSlug}/${m.attributes.slug}`);
                  } else {
                    navigate(
                      `/${mvpSlug}/${m.attributes.slug}/${s.attributes.slug}/${
                        s.attributes.questions.data[questionIndex - 1].id
                      }`
                    );
                  }
                }}
              />
              <Button
                label={
                  questionIndex === s.attributes.questions.data.length - 1
                    ? "Finish"
                    : "Next"
                }
                size="medium"
                onClick={() => {
                  const lastQuestion =
                    questionIndex === s.attributes.questions.data.length - 1;
                  if (lastQuestion) {
                    posthog.capture("sectionFinishButtonClick", {
                      type: s.attributes.type,
                      id: s.id,
                    });
                  }
                  schemaStore.updateQuestion(m, s, q.id, {
                    public: true,
                  });
                  if (lastQuestion && sec) {
                    const outcome = getOutcome(s, sec);
                    if (outcome === undefined) {
                      navigate(`/${mvpSlug}/${m.attributes.slug}`);
                    } else {
                      navigate(
                        `/${mvpSlug}/${m.attributes.slug}/${s.attributes.slug}/outcome`
                      );
                    }
                  } else {
                    navigate(
                      `/${mvpSlug}/${m.attributes.slug}/${s.attributes.slug}/${
                        s.attributes.questions.data[questionIndex + 1].id
                      }`
                    );
                  }
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
