import { useNavigate as useN } from "react-router-dom";
import { store } from "./store/store";
import { ActionType } from "./store/storeActions";
import { TransitionType } from "./store/StoreSchema";
export const useNavigate = () => {
  const navigate = useN();
  return (to: string, type: TransitionType = "push") => {
    store.dispatch({
      type: ActionType.SetTransitionType,
      params: {
        type,
      },
    });
    setTimeout(() => navigate(to), 10);
  };
};
