import classNames from "classnames";
import { memo, useCallback } from "react";
import posthog from "posthog-js";
import styles from "./SectionVideo.module.css";
import { CMSItem } from "@greenflagdate/shared";
import { mvpSlug } from "../../../lib/mvpSlug";
import {
  ModuleAttributes,
  SectionAttributes,
  isVideoSection,
} from "../../../lib/quizDataHelpers";
import { schemaStore } from "../../../lib/schemaStore";
import { SectionVideoThumbnail } from "../../../components/Video/SectionVideoThumbnail";
import { PlayerState, Video } from "../../../components/Video/Video";
import { useNavigate } from "../../../lib/useNavigate";

interface SectionVideoProps {
  module: CMSItem<ModuleAttributes>;
  section: CMSItem<SectionAttributes>;
  startTime: number;
  disabled?: boolean;
}

function SectionVideoRaw({
  module: m,
  section,
  startTime,
  disabled,
}: SectionVideoProps) {
  const navigate = useNavigate();
  const onTick = useCallback(
    (state: PlayerState) => {
      schemaStore.updateSectionVideoState(
        m.id,
        section.id,
        state.playTime,
        state.waitTime,
        state.currentTime
      );
    },
    [m.id, section.id]
  );
  const onComplete = useCallback(() => {
    posthog.capture("sectionCompleted", {
      type: section.attributes.type,
      id: section.id,
    });
    schemaStore.updateSection(section.id, m, { completed: true });
  }, [m, section.attributes.type, section.id]);
  if (!isVideoSection(section)) {
    return null;
  }

  return (
    <Video
      url={section.attributes.video.data.attributes.video.data.attributes.url}
      color="primary"
      disabled={disabled}
      thumbnail={
        <SectionVideoThumbnail
          title={section.attributes.name}
          length={section.attributes.subtitle}
          disabled={disabled}
          thumbnailImage={
            <img
              className={classNames(styles.image, styles.section)}
              src={
                section.attributes.video.data.attributes.thumbnail.data
                  .attributes.url
              }
              alt={`${section.attributes.name} video`}
              width={
                section.attributes.video?.data.attributes.thumbnail.data
                  .attributes.width
              }
              height={
                section.attributes.video?.data.attributes.thumbnail.data
                  .attributes.height
              }
            />
          }
        />
      }
      onTick={onTick}
      onComplete={onComplete}
      startTime={startTime}
      className={styles.sectionWrapper}
      onOpen={() => {
        schemaStore.updateSection(section.id, m, { completed: true });
        posthog.capture("sectionClick", {
          type: section.attributes.type,
          id: section.id,
        });
        history.replaceState(
          {},
          "",
          `/${mvpSlug}/${m.attributes.slug}/${section.attributes.slug}`
        );
      }}
      onClose={() => navigate(`/${mvpSlug}/${m.attributes.slug}`)}
    />
  );
}

export const SectionVideo = memo(SectionVideoRaw);
