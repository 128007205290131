import classNames from "classnames";
import styles from "./ActionPanel.module.css";
import { PropsWithChildren } from "react";

interface ActionPanelProps {
  fixed?: boolean;
  className?: string;
}

export function ActionPanel({
  children,
  fixed,
  className,
}: PropsWithChildren<ActionPanelProps>) {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.fixed]: fixed },
        className
      )}
    >
      {children}
    </div>
  );
}
